<template>
  <div class="content">
    <base-header class="pb-7">
      <div class="row align-items-center py-4 d-md-none">
        <div class="col-lg-6 col-7">
          <h6 class="h2 text-white d-inline-block mb-0">账号资料</h6>
          <nav aria-label="breadcrumb" class="d-none d-md-inline-block ml-md-4">
            <route-bread-crumb></route-bread-crumb>
          </nav>
        </div>
      </div>
    </base-header>
    <div class="container-fluid mt--7">
      <div>
        <card class="no-border-card" body-classes="px-0 pb-1" footer-classes="pb-2" v-loading="loading">
            <div class="px-4 border-bottom pb-3 mb-3">
              <div class="row mb-3" v-if="orgUpdate && orgUpdate.status === 2">
                <div class="col">
                  <el-alert type="warning" show-icon :title="'您的资料修改审核不通过，原因：' + orgUpdate.remark">
                  </el-alert>
                </div>
              </div>
              <div class="row">
                <div class="col-12 d-flex justify-content-between">
                    <span class="h3 ls-2">账号及企业资料</span>
                    <div>
                      <a class="btn btn-default ml-2" href="javascript:;" @click="openChangePassword">
                          <span class="el-icon-key"></span> 修改登录密码
                      </a>
                      <a class="btn btn-success" href="javascript:;" @click="openModify" v-if="!loginedUser.is_super">
                          <span class="el-icon-school"></span> 修改资料
                      </a>
                    </div>
                </div>
              </div>
            </div>
            <div class="px-4">
              <dl class="row">
                  <dt class="col-4 col-md-3 text-muted font-weight-light">账号名称</dt>
                  <dd class="col-8 col-md-9">{{ loginedUser.username }}</dd>
              </dl>
              <dl class="row" v-if="!loginedUser.is_super">
                  <dt class="col-4 col-md-3 text-muted font-weight-light">企业名称</dt>
                  <dd class="col-8 col-md-9">
                    {{ org.company_name }}
                    <span class="text-muted ml-2" v-if="orgUpdate && org.company_name!=orgUpdate.company_name">
                      <i class="el-icon-right"></i> {{ orgUpdate.company_name }}
                    </span>
                  </dd>
                  <dt class="col-md-3 text-muted font-weight-light">企业性质</dt>
                  <dd class="col-md-9">
                    {{ org.org_type }}
                    <span class="text-muted ml-2" v-if="orgUpdate && org.org_type!=orgUpdate.org_type">
                      <i class="el-icon-right"></i> {{ orgUpdate.org_type }}
                    </span>
                  </dd>
                  <dt class="col-md-3 text-muted font-weight-light" v-if="org.org_district||(orgUpdate && orgUpdate.org_district)">所属地区</dt>
                  <dd class="col-md-9" v-if="org.org_district||(orgUpdate && orgUpdate.org_district)">
                    {{ org.org_district || '无' }}
                    <span class="text-muted ml-2" v-if="orgUpdate && org.org_district!=orgUpdate.org_district">
                      <i class="el-icon-right"></i> {{ orgUpdate.org_district }}
                    </span>
                  </dd>
                  <dt class="col-md-3 text-muted font-weight-light">国企单位</dt>
                  <dd class="col-md-9">
                    {{ org.org_name }}
                    <span class="text-muted ml-2" v-if="orgUpdate && org.org_name!=orgUpdate.org_name">
                      <i class="el-icon-right"></i> {{ orgUpdate.org_name }}
                    </span>
                  </dd>
                  <hr>
                  <dt class="col-md-3 text-muted font-weight-light">联系人</dt>
                  <dd class="col-md-9">
                    {{ org.contact_name || '未填写'}}
                    <span class="text-muted ml-2" v-if="orgUpdate && org.contact_name!=orgUpdate.contact_name">
                      <i class="el-icon-right"></i> {{ orgUpdate.contact_name }}
                    </span>
                  </dd>
                  <dt class="col-md-3 text-muted font-weight-light">联系电话</dt>
                  <dd class="col-md-9">
                    {{ org.contact_phone || '未填写' }}
                    <span class="text-muted ml-2" v-if="orgUpdate && org.contact_phone!=orgUpdate.contact_phone">
                      <i class="el-icon-right"></i> {{ orgUpdate.contact_phone }}
                    </span>
                  </dd>
                  <dt class="col-md-3 text-muted font-weight-light">联系邮箱地址</dt>
                  <dd class="col-md-9">
                    {{ org.contact_email || '未填写' }}
                    <span class="text-muted ml-2" v-if="orgUpdate && org.contact_email!=orgUpdate.contact_email">
                      <i class="el-icon-right"></i> {{ orgUpdate.contact_email }}
                    </span>
                  </dd>
              </dl>
            </div>
        </card>
      </div>
    </div>
    <change-password v-if="loadChangePassword" :visible.sync="showChangePassword"></change-password>
    <el-drawer :visible="editing" :append-to-body="true" :modal-append-to-body="true" :before-close="beforeCloseModify"
      direction="ltr" size="50%"
      title="修改企业资料">
        <div class="px-4">
          <modify-form v-if="editing" @cancelled="closeModify" @saved="savedModify" :saving.sync="saving"></modify-form>
        </div>
    </el-drawer>
  </div>
</template>
<script>
import { mapState } from "vuex";
import RouteBreadCrumb from "@/components/Breadcrumb/RouteBreadcrumb";

export default {
  components: {
    RouteBreadCrumb,
    'change-password': () => import('./Widgets/ChangePassword'),
    'modify-form': () => import('./Orgs/Update.vue')
  },
  data() {
    return {
      loading: false,
      editing: null,
      saving: false,
      saved: false,
      loadChangePassword: false,
      showChangePassword: false,
      org: {},
      orgUpdate: null
    }
  },
  computed: {
    ...mapState({
      loginedUser: (state) => state.user.user || {}
    })
  },
  methods: {
    openChangePassword() {
        if(!this.loadChangePassword) this.loadChangePassword = true; 
        this.showChangePassword = true;
    },
    fetchData() {
      this.loading = true;
      this.axios.get("orgs/0")
      .then((res) => {
        if (res.data.errcode == 0) {
            this.org = res.data.data;
            this.orgUpdate = res.data.org_update
            this.loading = false;
        }
        else {
            this.$notifyErr('未能成功加载数据', res.data.errmsg);
        }
      })
      .catch((e) => {
        console.error(e);
        this.loading = false;
      })
    },
    openModify () {
      this.editing = true
      this.saved = false
    },
    savedModify () {
      this.saved = true
      this.modifyClosed()
    },
    closeModify () {
      this.modifyClosed()
    },
    modifyClosed () {
      if (this.saved) this.fetchData()
      this.editing = false
    },
    beforeCloseModify (done) {
      this.editing = false
      done()
    }
  },
  mounted() {
    this.fetchData()
  }
};
</script>
<style scoped>
  .el-table::before { height: 0 }
</style>